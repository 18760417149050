import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getLocalStorage, setLocalStorage} from "../utils/localStorageUtilities";
import {DEFAULT_AGENDA_PARAMS, getAgendaDisplayStartDate} from "../utils/agendaUtilities";
import {pick} from "../utils/utilities";
import {omit} from "lodash";

export const paginationPageSizes = [10, 20, 40, 60, 100];
export const defaultPagination = {pageSize: paginationPageSizes[2], current: 1};

const registrationSearchParams = ["firstName", "lastName", "email", "tokenId", "invitationToken"];
const sessionsSearchParams = ["session", "categories"];

export const getUrlSearchParams = () => {
  const urlSearchString = window.location.search;
  const searchParams =
    urlSearchString?.length > 0
      ? Object.fromEntries(new URLSearchParams(urlSearchString).entries())
      : {};
  return {
    registration: pick(searchParams, registrationSearchParams),
    sessions: {
      session: searchParams?.session,
      categories: searchParams?.categories?.split(","),
      customFields: Object.fromEntries(
        Object.entries(
          omit(searchParams, [...registrationSearchParams, ...sessionsSearchParams, "date"])
        ).map(([key, filterValueString]) => [key, filterValueString?.split(",")])
      ),
    },
  };
};

export const initialSearchParams = getUrlSearchParams();

export const viewSlice = createSlice({
  name: "view",
  initialState: {
    darkMode: getLocalStorage("darkMode"),
    searchParams: initialSearchParams,
    scroll: {},
    sorting: {},
    search: {},
    pagination: {},
    isModified: {},
    columnsBlacklist: getLocalStorage("columnsBlacklist", {}),
    agendaParams: getLocalStorage("agendaParams", {}),
    sessionsViewMode: getLocalStorage("sessionsViewMode", "list"),
    favorites: getLocalStorage("favorites", {}),
  },
  reducers: {
    setSearchParams: (state, action) => {
      state.searchParams = action.payload;
    },
    changeScroll: (state, action) => {
      state.scroll = {
        ...state.scroll,
        [window.location.pathname]: action.payload,
      };
    },
    changeSorting: (state, action) => {
      state.sorting = {
        ...state.sorting,
        [window.location.pathname]: action.payload,
      };
    },
    changeSearch: (state, action) => {
      state.search = {
        ...state.search,
        [window.location.pathname]: action.payload,
      };
    },
    changePagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        [window.location.pathname]: action.payload,
      };
    },
    changeDarkMode: (state, action) => {
      state.darkMode = action.payload;
      setLocalStorage("darkMode", state.darkMode);
    },
    changeIsModified: (state, action) => {
      state.isModified = {
        ...state.isModified,
        [action.payload.key]: action.payload.value,
      };
    },
    changeColumnsBlacklist: (state, action) => {
      state.columnsBlacklist = {
        ...state.columnsBlacklist,
        [action.payload.key]: action.payload.blacklist,
      };
      setLocalStorage("columnsBlacklist", state.columnsBlacklist);
    },
    changeAgendaParams: (state, action) => {
      const projectId = action.payload.projectId;

      // Update the state
      const newProjectParams = {
        ...state.agendaParams[projectId],
        ...action.payload.params,
      };
      state.agendaParams = {
        ...state.agendaParams,
        [projectId]: newProjectParams,
      };

      setLocalStorage("agendaParams", state.agendaParams);
    },
    changeSessionsViewMode: (state, action) => {
      state.sessionsViewMode = action.payload;
      setLocalStorage("sessionsViewMode", action.payload);
    },
    changeFavorites: (state, action) => {
      const projectId = action.payload.projectId;

      // Update the state
      state.favorites = {
        ...state.favorites,
        [projectId]: action.payload.favorites,
      };

      setLocalStorage("favorites", state.favorites);
    },
  },
});

const asyncActions = {};

export const viewSelectors = {
  selectDarkMode: (state) => state.view.darkMode,
  selectIsModified: (state, givenKey?) =>
    state.view.isModified[givenKey || window.location.pathname],
  selectScroll: (state) => state.view.scroll[window.location.pathname],
  selectSorting: (state) => state.view.sorting[window.location.pathname],
  selectSearch: (state) => state.view.search[window.location.pathname],
  selectPagination: (state) => state.view.pagination[window.location.pathname],
  selectColumnsBlacklist: (key) => (state) => state.view.columnsBlacklist[key],
  selectSearchParams: (state) => state.view.searchParams,
  selectSessionsViewMode: (state) => state.view.sessionsViewMode,

  // Memoized selector for agenda params
  selectAgendaParams: createSelector(
    [(state) => state.view.agendaParams, (state) => state.currentProject.project],
    (agendaParams, project) => {
      const currentProjectAgendaParams = agendaParams?.[project._id];
      return {
        ...DEFAULT_AGENDA_PARAMS,
        ...currentProjectAgendaParams,
        currentAgendaDate:
          currentProjectAgendaParams?.currentAgendaDate || getAgendaDisplayStartDate(project),
      };
    }
  ),

  // Memoized selector for favorites
  selectFavorites: createSelector(
    [(state) => state.view.favorites, (state) => state.currentProject.project],
    (favorites, project) => {
      return favorites?.[project._id] || [];
    }
  ),
};

export const viewReducer = viewSlice.reducer;

export const viewActions = {
  ...viewSlice.actions,
  ...asyncActions,
};
